.banner {
  background-color: orangered;
  width: 100%;
  height: 265px;
}
.Heading {
  width: 250px;
  padding-top: 8px;

  padding-left: 10px;
  font-size: 15px;
}
.Heading p:nth-child(1) {
  color: white;
  font-family: "Roboto", "Inter", sans-serif;
  font-size: 3vh;
  display: block;
}
.Heading p:nth-child(2) {
  color: white;
}
.numberofdays {
  color: orangered;
  background-color: white;
  width: 210px;
  margin-left: 10px;
  font-size: 1rem;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}
.lund {
  height: 48px;
  width: 48px;
  background-color: orangered;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  right: -23px;
}
.bannerSecond {
  position: absolute;
  height: 276px;
  width: 340px;
  top: -12.4px;
  right: 0px;
}
.bannerSecond img {
  width: 100%;
  height: 100%;
}

.btn {
  all: unset;
  color: white;
  background-color: orangered;
  height: 25px;
  width: 155px;
  font-size: large;
  border-radius: 25px;
  padding: 5px;
  text-align: center;
  border: 1px solid white;
  box-shadow: 0 0 5px white inset;
  margin: 0 auto;
}

.sideholder {
  position: relative;
  padding-left: 15px;
  padding-top: 5px;
  color: white;
}
.sides1 {
  position: absolute;
  width: 180px;
  right: 0px;
  top: 65px;
}
.sides2 {
  position: absolute;
  width: 180px;
  left: 10px;
  top: 65px;
}
.rewardsDisplay {
  width: 94%;
  height: 260px;
  margin: 0 auto;
  margin-top: 15px;
}
.row {
  height: 125px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 5px;
}
.column {
  background-color: white;
  width: 32%;
  height: 98%;
  border-radius: 8px;
  text-align: center;
}
.column p {
  margin-top: 10px;
  color: grey;
}
.column p:nth-child(1) {
  margin-bottom: 7px;
  color: black;
  margin-top: 5px;
}

.column img {
  width: 45px;
  margin: 0 auto;
}
.dayseven {
  height: 120px;
  background-color: white;
  overflow: hidden;
  width: 94%;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 8px;
  display: flex;
}

.boxonne img {
  height: 110px;
}
.boxdata {
  width: 50%;
  height: 100%;
  vertical-align: center;
  text-align: center;
  padding-top: 35px;
}
.boxdata p {
  padding-top: 15px;
}
.btnholder {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px;
}
