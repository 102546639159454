.phonenumberholder {
  display: inline-block;
  position: relative;
  bottom: 5px;
  left: 5px;
}
.p1 {
  padding-top: 5px;
  margin-top: 15px;
}
.passwordholder {
  display: inline-block;
  position: relative;
  bottom: 5px;
  left: 5px;
}
.p2 {
  padding-top: 5px;
  margin-top: 15px;
}
.mainview {
  width: 100%;
  margin: 0 auto;
  position: relative;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.punch {
  background-color: orangered;
  color: white;
  height: 70px;
  padding-left: 20px;
  font-size: smaller;
}
.loginform {
  width: 90%;
  margin: 0 auto;
}
.login {
  text-align: center;
  color: orangered;
  padding: 10px;
  border-bottom: 1px solid orangered;
}
.input {
  padding: 7px;
  border-radius: 10px;
  height: 42px;
  border: none;
  width: 100%;
  margin: 5px auto 5px auto;
  outline: none;
  appearance: none;
}
.inputsubmit {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding: 11px;
  margin-top: 29px;
  border-radius: 25px;
  background-color: orangered;
  color: white;
  border: none;
}
.register {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding: 11px;
  margin-top: 15px;
  border-radius: 25px;
  background-color: white;
  color: orangered;
  border: none;
  text-align: center;
  text-decoration: none;
}
.icons {
  height: 20px;
  width: 20px;
  color: orange;
}
.warning {
  color: red;
  font-size: 15px;
}
.register_Info {
  background-color: white;
  height: 250px;
  width: 80%;
  position: absolute;
  z-index: 1;
  top: 40%;
  right: 10%;
  border-radius: 20px;
  box-shadow: 0 0 200px grey;
  transition: 0.2s;
  overflow: hidden;
}
.hidden_dialog {
  background-color: white;
  height: 0px;
  width: 80%;
  position: absolute;
  z-index: 1;
  top: 40%;
  right: 10%;
  border-radius: 20px;
  box-shadow: 0 0 200px grey;
  overflow: hidden;
  transition: 0.2s;
}
.infoImage {
  height: 80px;
  width: 80px;
  text-align: center;
  color: forestgreen;
  margin-top: 15px;
}
.message {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
}
.Next {
  position: absolute;
  bottom: 12%;
  width: 80%;
  right: 10%;
}
.Next button {
  border: none;
  outline: none;
  background-color: orangered;
  color: white;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
}
