* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.mainConatiner {
  background-color: #0e0e0e;
}
.header {
  width: 100%;
  height: 50px;
  background-color: #202020;
  display: flex;
}
.logo {
  padding: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logo img {
  vertical-align: middle;
  height: 25px;
  padding-left: 10px;
}
.headerBalance {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 55%;
}
.headerBalance p {
  font-size: 19px;
  color: green;
}
.rupeeHeader {
  height: 15px;
  margin-right: -2px;
}
.msg {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18%;
}
.msgIcon {
  color: white;
  font-size: 19px;
}

.historyStrip {
  height: 45px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.prevHistory {
  background-color: black;
  color: violet;
  padding: 2px 4px 2px 4px;
  border-radius: 25%;
}
.spclHistory {
  position: absolute;
  background-color: #202020;
  right: 0;
  width: 55px;
  height: 25px;
  border-radius: 25px;
  box-shadow: 0 0 1px white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.displayArea {
  height: 250px;
  width: 98%;
  margin: 0 auto;
  margin-top: 8px;
  border: 1px solid black;
  box-shadow: 0 0 2px white;
  border-radius: 25px;
}
.betSetter {
  height: 180px;
  width: 98%;
  margin: 0 auto;
  background-color: #202020;
  margin-top: 10px;
  border-radius: 10px;
  padding-top: 15px;
}
.optionContainer {
  color: white;
  display: flex;
  background-color: #141516;
  width: 50%;
  margin: 0 auto;
  height: 25px;
  border-radius: 25px;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.on {
  background-color: #2c2d30;
  width: 50%;
  text-align: center;
  border-radius: 25px;
  height: 25px;
  padding-top: 2px;
}
.off {
  width: 50%;
  text-align: center;
  height: 25px;
  padding-top: 2px;
}
.MainOptionArea {
  height: 70%;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.division1 {
  width: 50%;
  height: 80%;
}
.division2 {
  background-color: #28a909;
  width: 50%;
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 1.2rem;
  overflow: hidden;
  border: 1px solid white;
}
.inputArea {
  color: white;
  border-radius: 26px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: black;
  height: 35px;
  width: 60%;
  margin: 0 auto;
}
.inputArea input {
  width: 100%;
  background-color: black;
  color: white;
  outline: none;
  border: none;

  text-align: center;
}
.inputfiledholder {
  margin-top: -4.5px;
  width: 50%;
}
.amountSelectorContainer {
  height: 50px;
}
