.walletview {
  background-color: #f7f7ff;
}
.walletInfo {
  background-color: orangered;
  margin-top: -10px;
  height: 200px;
}
.walleticonamount {
  width: 50%;
  margin: 10px auto;
  text-align: center;
  color: white;
}
.history {
  color: white;
}
.totalWidthdrawl {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin-top: 10px;
}
.totalDeposite {
  display: inline-block;
  text-align: center;
  width: 45%;
}
.typewallet {
  background-color: white;
  width: 94%;
  margin: 0 auto;
}

.typewallets {
  display: inline-block;
  width: 48%;
  height: 170px;
  margin-left: 7px;
  text-align: center;
  color: black;
}
.outerline {
  margin: 15px auto;
  width: 80px;
  text-align: center;
  height: 80px;
  background-color: orangered;
  border-radius: 50%;
  padding: 10px;
}
.innerline {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  padding-left: 2px;
}
.transfermain {
  border: none;
  border-radius: 15px;
  width: 80%;
  height: 35px;
  background-color: orangered;
  margin: 0 auto;
  display: block;
  color: white;
  margin-top: 8px;
}
.diffwallets {
  display: inline-block;
  height: 95px;
  width: 22%;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 15px;
  text-align: center;
}
a {
  text-decoration: none;
  color: black;
}
.widthslogan {
  font-size: 10px;
  margin-bottom: -10px;
}
.oversize {
  margin-bottom: -10px;
}
.grid {
  width: 94%;
  margin: 0 auto;
  padding-bottom: 70px;
}
.gridlinear {
  width: 100%;
  height: 80px;
}
.griditems {
  display: inline-block;
  background-color: white;
  width: 32.5%;
  margin: 1px;
  margin-top: 6px;
  height: 74px;
  border-radius: 5px;
  text-align: center;
  align-content: center;
}
