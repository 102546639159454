.maingames {
  width: 100%;
  height: auto;
  padding: 15px;
  margin: 10px 0;
  background-color: #f7f7ff;
}

.games {
  width: 100%;
  height: 150px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 0 0 25px 0;
  background-color: white;
}
.openerUpper1 {
  width: 100%;
  height: 75%;
  position: relative;
  padding: 10px 0 0 25px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url(../icons/first.png);
  background-size: cover;
}
.openerUpper2 {
  width: 100%;
  height: 75%;
  position: relative;
  padding: 10px 0 0 25px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url(../icons/sec.png);
  background-size: cover;
}
.openerUpper3 {
  width: 100%;
  height: 75%;
  position: relative;
  padding: 10px 0 0 25px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url(../icons/seclast.png);
  background-size: cover;
}
.openerUpper4 {
  width: 100%;
  height: 75%;
  position: relative;
  padding: 10px 0 0 25px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url(../icons/last.png);
  background-size: cover;
}
.gamelogo {
  position: absolute;
  top: -15px;
  right: 0;
}
.gamelogoimg {
  height: 65px;
}
.discription {
  margin-top: 25px;
}
.openerlower {
  width: 100%;
  color: black;
  height: 25%;
  position: relative;
}
.openerloweri {
  display: inline-block;
  height: 30px;
  margin: 5px;
}
.openerlowerp {
  position: absolute;
  top: 12px;
  font-size: 12px;
}
