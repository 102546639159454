.Navigation {
  height: 45px;
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.history {
  color: orange;
  display: inline-block;
  border-bottom: 1px solid orange;
  height: 45px;
  padding-top: 12px;
  width: 60px;
  text-align: center;
  transition: 1s;
}
.historyNormal {
  color: #a4a4a4;
  display: inline-block;

  height: 45px;
  padding-top: 12px;
  width: 60px;
  text-align: center;
  transition: 1s;
}
.rules {
  color: orange;
  display: inline-block;
  border-bottom: 1px solid orange;
  height: 45px;
  padding-top: 12px;
  width: 60px;
  text-align: center;
  transition: 1s;
}
.normalrule {
  color: #a4a4a4;
  display: inline-block;
  height: 45px;
  padding-top: 12px;
  width: 60px;
  text-align: center;
  transition: 1s;
}
