.heading {
  background-color: white;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  color: #3b3a3a;
}
.intrest {
  display: block;
  background-color: white;
  padding: 5px;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  margin-top: 10px;
  border-radius: 28px;
  color: red;
}
.walletView {
  background-image: url(../icons/TotalAssetsBg-86d46fff.png);
  height: 125px;
  width: 93.4%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 10px;
  overflow: hidden;
}
.first {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}
.vaultimage {
  margin-top: 10px;
}
.right {
  padding: 1px 14px;
  margin-right: 10px;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  vertical-align: middle;
}
.second {
  vertical-align: middle;
}
.mainView {
  background-color: white;
  width: 94%;
  height: 215px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.data {
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  color: #a4a4a4;
  font-size: 15x;
}
.intersinfo {
  display: block;
  color: #a4a4a4;
  border: 1px solid #a4a4a4;
  width: 140px;
  padding: 1px 9px;
  margin-top: 84x;
  margin-left: 7%;
  border-radius: 25px;
  font-size: 12px;
}
.btnholder {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  align-items: center;
}
.btnholder div {
  width: 46%;
}

.btnholder button {
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid orange;
  background-color: white;
  color: orange;
  border-radius: 8px;
}
.notice {
  color: red;
  font-size: 12px;
  padding: 2px 8px;
  margin-top: 10px;
}
.extra {
  position: absolute;
  bottom: 8px;
  transform: translateX(75%);
  font-size: 12px;
  color: #a4a4a4;
}
.history {
  width: 94%;
  margin: 0 auto;
  margin-top: 25px;
}
.historybtn {
  width: 94%;
  margin: 0 auto;
  margin-top: 25px;
}
.historybtn button {
  width: 100%;
  padding: 12px;
  border: 1px solid orange;
  outline: none;
  background-color: transparent;
  border-radius: 25px;
  color: orange;
}
.showpopup {
  background-color: white;
  transition: 0.7s;
  transition-timing-function: ease;
  width: 85%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  top: 230px;
  transform: translateX(9%);
  height: 305px;
  border-radius: 26px;
  overflow: hidden;
  display: block;
  box-shadow: 0 0 420px grey;
}
.hidepopup {
  background-color: white;
  margin: 0 auto;
  position: absolute;
  top: 230px;
  transform: translateX(9%);
  border-radius: 26px;
  overflow: hidden;
  height: 0px;
}
.tipimage {
  height: 90px;
  text-align: center;
  margin-top: 15px;
}
.tipimage img {
  height: 100%;
}
.btn {
  color: orange;
  outline: none;
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10px;
  background-color: white;
  border: 1px solid orange;
  border-radius: 28px;
  height: 45px;
}
.showInput {
  background-color: white;
  position: absolute;
  width: 98%;
  transform: translateX(1%);
  bottom: 0;
  transition: 0.4s;
  height: 410px;
  overflow: hidden;
  box-shadow: 0 0 10px grey;
  border-radius: 20px 20px 0 0;
  padding: 10px 0 0 0;
}

.hideInput {
  background-color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 0.4s;
  height: 0px;
  overflow: hidden;
  padding: 10px 0 0 0;
}
.inputheading {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0 0 0;
}
.headingslogan {
  width: 89%;
  text-align: center;
  padding-left: 10%;
}
.secondslogan {
  color: #a4a4a4;
  background-color: #f7f7f7;
  display: block;
  width: 230px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 2px 4px;
  text-align: center;
  border-radius: 18px;
}
.secondslogan span {
  color: orange;
  margin-left: 20px;
}
.inputDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  margin-top: 23px;
  width: 94%;
}
.inputDiv input {
  width: 80%;
  outline: none;
  border: none;
}
.inputDiv p {
  width: 20%;
  text-align: center;
  color: red;
}
.buttonsHolder {
  width: 100%;
  margin-top: 10px;
}
.row {
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}
.unckeckedbutton {
  height: 100%;
  width: 23.5%;
  outline: none;
  border: none;
  background-color: #f6f6f6;
  border-radius: 8px;
  font-size: 18px;
}
.checkedButton {
  height: 100%;
  width: 23.5%;
  outline: none;
  border: none;
  background-color: orange;
  color: white;
  border-radius: 8px;
  font-size: 18px;
}
.availablebalanceView {
  display: flex;
  margin-top: 15px;
  padding-left: 10px;
}
.availablebalanceView p:nth-child(1) {
  width: 50%;
  background-color: #f6f6f6;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  text-align: start;
  vertical-align: "middle";
}
.availablebalanceView p:nth-child(2) {
  color: red;
  width: 44%;
  padding: 7px;
  padding-left: 48px;
  font-size: 12px;
  vertical-align: "middle";
}
.estimated {
  color: #a4a4a4;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 10px;
}
.finalbutton {
  position: absolute;
  bottom: 0;
  width: 100%;

  height: 45px;
}
.finalbutton input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
}
