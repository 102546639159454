.moneyview {
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
}
.moneyviewinner {
  width: 94%;
  background-image: url("../icons/TotalAssetsBg-86d46fff.png");
  height: 150px;
  margin: 0 auto;
  border-radius: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}
.slogan {
  width: 100%;

  height: 40px;
  padding-top: 10px;
}
.slogan img {
  color: white;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.slogan p {
  color: white;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.slogan img {
  height: 20px;
}
.paisa {
  height: 50px;
  padding-left: 22px;
}
.paisa h1 {
  vertical-align: middle;
  color: white;
  margin-left: -0px;
  display: inline-block;
  font-size: 24.54px;
}
.paisa .logo {
  color: white;
  vertical-align: middle;
  height: 22.51px;
}
.refresh {
  height: 16px;
  vertical-align: middle;
  margin-left: 8px;
}
