.maincontainer {
  width: 100%;
  height: 100%;

  background: url(../assets/images/bg10-76abb4b7.png) no-repeat center,
    linear-gradient(117.29deg, #eeaf3a 21.85%, #f98b3b 67.02%);
  border-radius: 5px;
}
.heading {
  display: flex;
  height: 50%;
  width: 94%;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 10px;
}
.left {
  height: 100%;
  width: 70%;
}
.right {
  overflow: hidden;
  width: 30%;
  height: 100%;
}
.right img {
  height: 86%;
  float: right;
}
.first {
  display: flex;

  height: 28px;
  justify-content: space-between;
  align-items: center;
}
.first img {
  height: 28px;
}
.first h1 {
  color: white;
  vertical-align: middle;
  font-size: 24px;
}
.sec {
  color: white;

  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  padding: 2px 4px;
}
.mentenenceinfo {
  color: white;
  margin-top: 5px;
  font-size: 10px;
  padding-left: 5px;
  margin-top: 15px;
}
.stage2 {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.f {
  margin-left: 12px;
  padding: 2px 8px;
  border-radius: 50px;
  background-color: #ffb64b;

  font-size: 12px;
  color: white;
}
.se {
  font-size: 12px;
  color: white;
  margin-right: 12px;
}
.stage3 {
  height: 10px;
  width: 94%;
  background-color: #ffb64b;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 10px;
  box-shadow: 0 0.01333rem #ffb64b, inset 0 0.05333rem 0.05333rem #d7610b;
}
.bar {
  height: 100%;
  width: 10%;
  background-color: gold;
  border-radius: 25px;
  box-shadow: 0 0 5px grey inset;
}
.stage4 {
  color: white;
  font-size: 10px;
  margin: 10px 0 0 12px;
}
