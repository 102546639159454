.header {
  width: 100%;
  height: 40px;
  background-color: white;
  text-align: center;
  position: relative;
}
.header p {
  margin-right: 10px;
  padding-top: 10px;
  display: inline-block;
}
.header p:nth-child(2) {
  margin: 0 auto;
  position: absolute;
  right: 10px;
  top: 5px;
  color: grey;
  font-size: 12px;
}
.methodholder {
  width: 94%;
  height: 80px;
  margin: 0px auto;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
}
.card img {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  height: 30px;
  width: 40px;
}
.card p {
  text-align: center;
  padding: 10px;
  color: #a4acbd;
}
.card {
  height: 100%;
  background-color: white;
  width: 120px;
  margin-left: 15px;
  border-radius: 8px;
}
.addbankcard {
  width: 94%;
  height: 90px;
  margin: 0 auto;
  background-color: white;
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px 0;
}
.addbankcard img {
  display: block;
  height: 30px;
  margin: 0 auto;
}

.addbankcard p {
  color: #a4acbd;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
  font-size: small;
}
.container {
  width: 94%;
  margin: 0 auto;

  background-color: white;
  margin-top: 10px;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
.depositeintput {
  background-color: #f7f7ff;
  height: 45px;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 25px;
  border-radius: 25px;
  display: flex;
  overflow: hidden;
  justify-content: start;
  align-items: middle;
}
.rupee {
  width: 12%;
  padding-left: 15px;
  margin-top: 10px;
  color: orange;
  font-size: 15px;
  padding-top: 2px;
}
.rupee::after {
  content: "";
  border: 1px solid lightgrey;
  margin-left: 9px;
}
.input {
  height: 30px;
  margin-top: 7px;
  padding-left: 10px;
  color: orange;
  outline: none;
  border: none;
  background-color: #f7f7ff;
}
.inputbox {
  width: 70%;
}
.cross {
  width: 18%;
}
.div {
  font-size: 35px;
  padding-top: 8px;
  float: right;
  padding-right: 5px;
  color: darkgrey;
}
.submitholder {
  width: 94%;
  margin: 0 auto;
  overflow: hidden;
}
.submitholder input {
  all: unset;
  background-color: lightgrey;
  color: grey;
  width: 94%;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}
.submitholder .on {
  all: unset;
  background-color: orange;
  color: white;
  width: 94%;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
  margin-top: 15px;
}

.withdrawlinfo {
  font-size: 13px;
  display: block;
  color: #a4acbd;
  width: 100%;
  padding: 5px 15px;
}
.withdrawlinfo:nth-child(3) {
  margin-bottom: 10px;
}
.withdrawlinfo:nth-child(3) span {
  float: right;
  color: red;
}
.WidthdrawlInstruction {
  width: 94%;
  border: 1px solid #a4acbd;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 15px 8px 15px 25px;
}
.WidthdrawlInstruction ul {
  list-style-type: disc;
}
.WidthdrawlInstruction ul li {
  vertical-align: middle;
  font-size: 15px;
  line-height: 25px;
  color: #a4acbd;
}
.withdrawlHistory {
  margin-top: 5px;
  width: 94%;
  height: 100px;
  background-color: red;
  margin: 0 auto;
  border-radius: 8px;
  padding-bottom: 10px;
}
