.card {
  width: 100%;
  height: 208px;
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
  background-color: white;
}
.status {
  width: 100%;
  background-color: white;
  padding: 15px 12px 15px 12px;
  border-bottom: 1px solid lightgrey;
}
.dataholder {
  padding: 5px 8px;
}
.left {
  display: inline-block;
  font-size: 15px;
  color: #a4acbd;
  margin: 4px 0px;
  padding: 0px 10px;
}
.right {
  display: inline-block;
  float: right;
  font-size: 15px;
  color: #a4acbd;
  font-size: 12px;
  padding: 5px 8px;
  vertical-align: middle;
}
