.levelPerks {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  background-color: white;
}
.heading {
  border-bottom: 1px solid grey;
  padding: 10px 0px 5px 0px;
}
.headinginfo {
  color: rgb(43, 40, 40);
  width: 400px;
  overflow: hidden;
  display: inline-block;
  font-size: 18px;
}

.perkslist {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin-top: 5px;
}
.first {
  height: 100%;
  width: 19%;

  overflow: hidden;
}
.first img {
  height: 90%;
  margin-left: 5px;
}
.sec {
  height: 100%;
  width: 60%;
}
.third {
  height: 100%;
  width: 25%;
}
.listHeading {
  font-weight: bold;
  padding: 4px 2px;
  margin-top: 0px;
}
.listInfodata {
  font-size: 12px;
  margin-top: 0px;
  color: #a4a4a4;
}
.moneyoperkingo {
  background-color: white;
  height: 40%;
  width: 90%;
  margin: 4px auto;
  border-radius: 8px;
  border: 1px solid gold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moneyoperkingosec {
  background-color: white;
  height: 50%;
  width: 90%;
  margin: 15px auto;
  border-radius: 8px;
  border: 1px solid gold;
}
.yyx {
  display: block;
  width: 100%;
  text-align: center;
  color: gold;
}

.xyximg {
  width: 20%;
  margin-right: 4px;
}
.Benifits {
  width: 100%;
  background-color: white;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}
.benifitsheader {
  height: 45px;

  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px 10px;
}

.gridItem {
  display: flex;
  width: 100%;
  height: 220px;
  justify-content: space-evenly;
  margin-bottom: 15px;
  font-size: 15px;
}
.Item {
  width: 47%;
  height: 100%;
}
.imagebar {
  height: 100px;
  width: 100%;
  background-image: url(../assets/images/welfare1-eee87ee1.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: orange;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.imagebar2 {
  height: 100px;
  width: 100%;
  background-image: url(../assets/images/welfare2-cf757d28.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: orange;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.imagebar3 {
  height: 100px;
  width: 100%;
  background-image: url(../assets/images/welfare4-5642a4c8.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: orange;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.imagebar4 {
  height: 100px;
  width: 100%;
  background-image: url(../assets/images/welfare5-8b250748.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: orange;
  border-radius: 8px 8px 0 0;
  position: relative;
}
.imagebar3 p {
  position: absolute;
  bottom: 0px;
  background: transparent;
  box-shadow: 0 0 20px black inset;
  display: inline-block;
  width: 100%;
  color: antiquewhite;
}
.imagebar4 p {
  position: absolute;
  bottom: 0px;
  background: transparent;
  box-shadow: 0 0 20px black inset;
  display: inline-block;
  width: 100%;
  color: antiquewhite;
}
.imagebar2 p {
  position: absolute;
  bottom: 0px;
  background: transparent;
  box-shadow: 0 0 20px black inset;
  display: inline-block;
  width: 100%;
  color: antiquewhite;
}
.imagebar p {
  position: absolute;
  bottom: 0px;
  background: transparent;
  box-shadow: 0 0 20px black inset;
  display: inline-block;
  width: 100%;
  color: antiquewhite;
}
.infobar {
  padding: 5px;
  background-color: #f7f7f7;
  padding-bottom: 10px;
  border-radius: 0 0 8px 8px;
  height: 75px;
}
.infobar p {
  color: #a4a4a4;
  font-size: 15px;
  padding-top: 4px;
}
.recieve {
  background-color: lightgrey;
  outline: none;
  border: none;
  width: 100%;
  height: 30px;
  margin-top: 15px;
  border-radius: 25px;
}
.miniwallet {
  height: 15px;
  margin-right: 5px;
  margin-left: 5px;
}
