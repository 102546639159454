a {
  text-decoration: none;
}

.alpha {
  width: 100%;
  height: 300px;
  background-color: orangered;

  position: relative;
}
.todaysCommission {
  display: block;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: white;
  padding-top: 25px;
}

.commissionHeader {
  text-align: center;
  width: 90%;
  margin: 10px auto;
}
.commissionHeader h4 {
  background-color: white;
  color: orangered;
  display: block;
  width: 65%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 15px;
}
.commissionHeader p {
  font-size: small;
  color: white;
}

.PromotionalData {
  position: absolute;
  width: 95%;
  top: 150px;
  left: 2%;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 10px;
}

.headingCommission p {
  background-color: orange;
  display: inline-block;
  text-align: center;
  width: 49.865%;
  padding: 8px;
  color: white;
  border-radius: 5px 0 0 0;
}
.data {
  display: inline-block;
  text-align: center;
  padding: 5px;
  width: 49.1%;
  font-size: small;
}
.inviteLink {
  margin-top: 110px;
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.inviteLink h3 a {
  text-decoration: none;
  padding: 5px;
  color: white;
  background-color: orangered;
  display: block;
  width: 75%;
  margin: 0 auto;
  border-radius: 20px;
}
.list {
  width: 365px;
  background-color: white;
  margin: 0 auto;
  margin-top: 10px;
  padding: 15px;
}
.listItems {
  display: inline-block;
  color: black;
}
