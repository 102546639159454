.notification_center {
  width: 100%;
  height: 35.27px;
  background-color: #fffbe8;
  text-align: center;
  position: relative;
  border-radius: 10px;
  margin-top: 10px;
}
.notice {
  color: orangered;
  text-align: left;
  font-size: small;
  height: 16px;
  overflow: hidden;
  animation-name: scroll;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
  animation-direction: normal;
  margin-top: 6px;
  margin-left: 5px;
}
@keyframes scroll {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
.details {
  background-color: orangered;
  color: white;
  text-align: center;
  display: block;
  position: absolute;
  right: 10px;
  border-radius: 20px;
  padding: 4px;
  top: 4px;
  width: 20%;
  font-size: small;
}
.actuator {
  display: inline-block;
  width: 60%;
  overflow: hidden;
  position: absolute;
  left: 0px;
}
